<template>
  <div class="fragment">
    <div class="section-label large"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['packingTypes_PackageType'])"></div>
      {{$t('packingTypes_UserPackType.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--25 custom-col--sm-100 mb-0"
           v-for="(item, index) in packageTypes"
           :key="index"
      >
        <LargeRadio
                class="soft-text"
                :label="item.currentTranslate.name"
                :name="'type1'"
                :checked="_.has(PT.data.packageType, 'id') && item.id === PT.data.packageType.id"
                @change="changeType(item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import LargeRadio from "../../../../../UI/radiobuttons/LargeRadio/LargeRadio";

  export default {
    name: "PackagingTypesRadio",
    components: {LargeRadio},

    props: {
      PT: Object,
    },

    data() {
      return {
        packageTypes: [],
      }
    },

    mounted() {
      if (this.$route.query?.type === 'gift_pack') {
        this.$store.dispatch('getDeliveryPackingTypeGiftForAdmin').then(response => {
          this.packageTypes = this.getRespPaginateData(response)
          this.PT.setPackageType(this.packageTypes.length ? this.packageTypes[0] : null)
        }).catch(error => this.createErrorLog(error))
      }
      else {
        this.$store.dispatch('getDeliveryPackingTypeForAdmin').then(response => {
          this.packageTypes = this.getRespPaginateData(response)
        })
      }
    },

    methods: {
      changeType(item) {
        this.PT.setPackageType(item)
      },
    }
  }
</script>

<style scoped>

</style>
