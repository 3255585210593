<template>
  <div class="custom-col custom-col--33 custom-col--md-100 logo-scoped">
    <template v-if="PT.data.Files.data.maxCountFiles - Object.keys(PT.data.Files.data.downloadFiles).length > 0"
    >
      <div >
        <DropZone class="drop-zone-bg"
                  :parentFiles="PT.data.Files.data.files"
                  :multiple="true"
                  :maxCount="PT.data.Files.data.maxCountFiles - PT.data.Files.data.downloadFiles.length"
                  :maxSize="PT.data.Files.data.maxSizeFiles"
                  @changeImg="changeImg"
        />
      </div>
    </template>

    <template v-if="Object.keys(PT.data.Files.data.downloadFiles).length > 0">
      <div v-for="(file, indexFile) in PT.data.Files.data.downloadFiles"
           :key="indexFile + '2' + images"
      >
        <div class="site-document d-flex justify-content-center">
          <div class="site-document__remove" @click="removeDocument(parseInt(file.id))">
            <CloseIcon/>
          </div>
          <div class="site-document__img">
            <img :src="`data:${file.mime_type};base64,` + file.imageBase64"
                 @click="showSingle(file.id)" alt="img">
          </div>
        </div>
      </div>

    </template>

    <!-- all props & events -->
    <Lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
    ></Lightbox>
  </div>
</template>

<script>
  import Lightbox from 'vue-easy-lightbox'
  import CloseIcon from '../../../../../../../public/img/modal-group/close-icon.svg?inline'
  import DropZone from "../../../../../coreComponents/DropZone/DropZone";

  export default {
    name: "PackagingTypesFile",
    components: {
      Lightbox,
      CloseIcon,
      DropZone,
    },

    props: {
      PT: Object,
    },

    data() {
      return {
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, // default:

        images: 0,
      }
    },

    mounted() {

    },

    methods: {
      changeImg(files, maxSizeError) {

        let file = files[0].file;
        this.getBase64(file).then(data => {
          let dataFile = Object.assign([], files[0])
          let base64Img = data.split('base64,')
          dataFile.base64String = base64Img[1]
          this.PT.data.Files.setFiles([dataFile])
        });

        this.PT.data.Files.setFilesMaxSizeError(maxSizeError)
      },


      showSingle(id) {
        this.$store.dispatch('getFileFromServer', id).then((response) => {
          this.imgs = []
          this.imgs.push({
            title: 'img',
            src: `data:image/png;base64,` + response[id]
          })
          this.show()
        })
      },

      removeDocument(id) {
        this.$store.dispatch('removeUserDocument', id).then(() => {
          this.PT.removePackagingTypesFile(id - 1)
          this.images = id
        })
      },


      handleHide() {
        this.visible = false
      },

      show() {
        this.visible = true
      },
    }
  }
</script>

<style scoped>

  .site-document__img {
    max-width: 105px;
    min-width: 66px;
    height: 53px;
  }
</style>
