<template>
  <PackagingTypesCreateAdmin
          v-if="isAdmin"
          :PT="PT"
          @save="save({edit: false})"
  />
</template>

<script>
  import PackagingTypesCreateAdmin from "./PackagingTypesCreateAdmin/PackagingTypesCreateAdmin";
  import {PackagingTypes} from "../../models/PackagingTypes";
  import {packagingTypesMixin} from "../../../../../mixins/packagingTypesMixins/packagingTypesMixin";

  export default {
    name: "PackagingTypesCreate",
    components: {
      PackagingTypesCreateAdmin,
    },

    mixins: [packagingTypesMixin],

    data() {
      return {
        PT: new PackagingTypes(),
      }
    },


    mounted() {

    },

    methods: {


    }

  }
</script>

<style scoped>

</style>
