
export const packagingTypesMixin = {

  methods: {

    changeUser(user) {
      this.PT.data.User.setUserData(user)
    },

    save({edit, id = false}) {

      let saveType
      let typeCreate = 'createPackagingPrice'
      let typeEdit = 'updatePackagingPrice'

      /**
       * Check if Type Gift Papers
       */
      let ifGiftPapers = this.$route.query.papers
      if(ifGiftPapers){
        typeCreate = 'createGiftPapers'
        typeEdit = 'updateGiftPapers'
      }

      if(!this.PT.firstValidation(ifGiftPapers)) return

      let data = this.PT.prepareSave(this.$store.getters.GET_LANGUAGES, ifGiftPapers)

      saveType = typeCreate

      if(edit) {
        saveType = typeEdit
        data = {
          data: data,
          id: id
        }
      }

      this.$store.dispatch(saveType, data).then((response) => {

        if(!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if (ifGiftPapers && this.PT.data.Files.getFiles().length > 0) {
              this.createGiftPaperFiles(this.getRespData(response).id).then(() => {
                this.$router.push(this.$store.getters.GET_PATHS.mainSettingsPackagingTypes)
                if(edit) {
                  this.openNotify('success', 'common_notificationRecordChanged')
                } else {
                  this.openNotify('success', 'common_notificationRecordCreated')
                }
              })
              return
            }

            if(edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }
            this.$router.push(this.$store.getters.GET_PATHS.mainSettingsPackagingTypes)
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },



    createGiftPaperFiles(id) {
      let reader = new FormData()

      this.PT.data.Files.getFiles().map((item) => {
        if (!this._.has(item, 'maxCount') && !item.maxSizeError) {
          reader.append(`file`, item.file)
        }
      })
      reader.append("gift_paper_id", id)

      return this.$store.dispatch('addPhotoToGiftPapers', {id: id, data: reader}).then((response) => {
        if(!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

      })
    },

  }

}
