<template>
  <form class="order-create__section">
    <div class="order-create__row custom-row order-wrap">
      <div class="custom-col custom-col--66 custom-col--md-100">


        <PackagingTypesRadio
                :PT="PT"
                v-if="!$route.query.papers"
        />

        <div class="section-label large"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['packingTypes_Name'])"></div>
          {{$t('packingTypes_Name.localization_value.value')}}
        </div>

        <div class="order-create__row custom-row">
          <div class="custom-col  custom-col--md-100"
          >
<!--               v-bind:class="{'custom-col&#45;&#45;50': !$route.query.papers}"-->
            <DefaultInput
                    :label="$t('packingTypes_Name.localization_value.value') + ' (English)'"
                    v-bind:class="{'ui-no-valid': PT.validation.nameEn}"
                    :error="PT.validation.nameEn"
                    :errorTxt="$t(`${PT.validationTranslate.nameEn}.localization_value.value`)"
                    v-model="PT.data.nameEn"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--md-100">
            <DefaultInput
                    :label="$t('packingTypes_Name.localization_value.value') + ' (Ukrainian)'"
                    v-bind:class="{'ui-no-valid': PT.validation.nameUa}"
                    :error="PT.validation.nameUa"
                    :errorTxt="$t(`${PT.validationTranslate.nameUa}.localization_value.value`)"
                    v-model="PT.data.nameUa"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--md-100">
            <DefaultInput
                    :label="$t('packingTypes_Name.localization_value.value') + ' (Russian)'"
                    v-bind:class="{'ui-no-valid': PT.validation.nameRu}"
                    :error="PT.validation.nameRu"
                    :errorTxt="$t(`${PT.validationTranslate.nameRu}.localization_value.value`)"
                    v-model="PT.data.nameRu"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--md-100">
            <DefaultInput
                    :label="$t('packingTypes_Name.localization_value.value') + ' (Polish)'"
                    v-bind:class="{'ui-no-valid': PT.validation.namePl}"
                    :error="PT.validation.namePl"
                    :errorTxt="$t(`${PT.validationTranslate.namePl}.localization_value.value`)"
                    v-model="PT.data.namePl"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--md-100">
            <DefaultInput
                    :label="$t('packingTypes_Name.localization_value.value') + ' (Latvian)'"
                    v-bind:class="{'ui-no-valid': PT.validation.nameLv}"
                    :error="PT.validation.nameLv"
                    :errorTxt="$t(`${PT.validationTranslate.nameLv}.localization_value.value`)"
                    v-model="PT.data.nameLv"
            />
          </div>
        </div>



        <div class="section-label large"
             v-if="!$route.query.papers"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['packingTypes_Usage'])"></div>
          {{$t('packingTypes_Usage.localization_value.value')}}
        </div>

        <div class="order-create__row custom-row">
          <PackagingTypesUserPack
                  v-if="!$route.query.papers"
                  :PT="PT"
          />

          <div class="custom-col custom-col--25 custom-col--md-33 custom-col--sm-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['packingTypes_Price'])"></div>
            <InputSum
                    :label="$t('packingTypes_Price.localization_value.value')"
                    :icoType="'dollar'"
                    v-bind:class="{'ui-no-valid': PT.validation.price}"
                    :error="PT.validation.price"
                    :errorTxt="$t(`${PT.validationTranslate.price}.localization_value.value`)"
                    v-model="PT.data.price"
            />
          </div>
        </div>

        <div class="order-create__row custom-row"
             v-if="!$route.query.papers">

          <div class="custom-col custom-col--75 custom-col--sm-100 d-flex justify-content-between"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['packingTypes_packTypeBarcode'])"></div>
            <DefaultInput
                class="w-100"
                :label="$t('packingTypes_packTypeBarcode.localization_value.value')"
                v-bind:class="{'ui-no-valid': PT.validation.barcode}"
                :error="PT.validation.barcode"
                :errorTxt="$t(`${PT.validationTranslate.barcode}.localization_value.value`)"
                v-model="PT.data.barcode"
            />

            <MainButton class="white-space-nowrap brown ml-3"
                        :value="$t('common_print.localization_value.value')"
                        @click.native="printBarcode"
            >
            </MainButton>

          </div>


        </div>


        <div class="order-create__row custom-row"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['packingTypes_Enable'])"></div>
          <div class="custom-col">
            <DefaultCheckbox
                    :label="$t('packingTypes_Enable.localization_value.value')"
                    :value="PT.data.status"
                    @input="(val) => {PT.data.status = val}"
            />
          </div>
        </div>

      </div>
      <!--<div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100 logo-scoped">-->
      <!--<DropZone class="drop-zone-bg"-->
      <!--:parentFiles="files"-->
      <!--@changeImg="changeImg"-->
      <!--:maxSize="'5'"-->
      <!--/>-->
      <!--</div>-->

      <PackagingTypesFile
              :PT="PT"
      />
    </div>

  </form>
</template>

<script>
  import PackagingTypesRadio from "../chunks/PackagingTypesRadio/PackagingTypesRadio";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import PackagingTypesUserPack from "../chunks/PackagingTypesUserPack/PackagingTypesUserPack";
  import InputSum from "../../../../UI/inputs/InputSum/InputSum";
  import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import PackagingTypesFile from "../chunks/PackagingTypesFile/PackagingTypesFile";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  export default {
    name: "StepOne",
    components: {
      MainButton,
      PackagingTypesFile,
      DefaultCheckbox,
      InputSum,
      PackagingTypesUserPack,
      DefaultInput,
      PackagingTypesRadio
    },

    props: {
      PT: Object,
    },

    methods: {
      printBarcode() {

        let routeData = this.$router.resolve({
          path: this.$store.getters.GET_PATHS.generatePdfFile + '/packing-type',
          query: {
            id: this.PT.getId(),
            format: 'pdf'
          }
        })

        window.open(routeData.href, '_blank');
      },

    }

  }
</script>

<style scoped>

</style>
