<template>
  <modal
          @close="$emit('close')"
          class="save-drafts-modal save-drafts"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_SaveInDrafts',
          ])"></div>
        {{ $t('packingTypes_edit.localization_value.value') }}?
      </div>

    </template>
    <template slot="body">
      <div class="save-drafts__content">
        <div class="custom-row">
          <div class="custom-col custom-col--50 custom-col--sm-100 "
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['packingTypes_nameEn'])"></div>
            <DefaultInput
                    :label="$t('packingTypes_nameEn.localization_value.value')"
                    v-bind:class="{'ui-no-valid': PT.validation.nameEn}"
                    :error="PT.validation.nameEn"
                    :errorTxt="$t(`${PT.validationTranslate.nameEn}.localization_value.value`)"
                    v-model="PT.data.nameEn"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100 "
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['packingTypes_nameRu'])"></div>
            <DefaultInput
                    :label="$t('packingTypes_nameRu.localization_value.value')"
                    v-bind:class="{'ui-no-valid': PT.validation.nameRu}"
                    :error="PT.validation.nameRu"
                    :errorTxt="$t(`${PT.validationTranslate.nameRu}.localization_value.value`)"
                    v-model="PT.data.nameRu"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100 "
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['packingTypes_nameUa'])"></div>
            <DefaultInput
                    :label="$t('packingTypes_nameUa.localization_value.value')"
                    v-bind:class="{'ui-no-valid': PT.validation.nameUa}"
                    :error="PT.validation.nameUa"
                    :errorTxt="$t(`${PT.validationTranslate.nameUa}.localization_value.value`)"
                    v-model="PT.data.nameUa"
            />
          </div>
        </div>

        <div class="custom-row">
          <div class="custom-col custom-col--50 custom-col--sm-100 "
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['packingTypes_descEn'])"></div>
            <DefaultInput
                    :label="$t('packingTypes_descEn.localization_value.value')"
                    v-bind:class="{'ui-no-valid': PT.validation.descEn}"
                    :error="PT.validation.descEn"
                    :errorTxt="$t(`${PT.validationTranslate.descEn}.localization_value.value`)"
                    v-model="PT.data.descEn"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100 "
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['packingTypes_descRu'])"></div>
            <DefaultInput
                    :label="$t('packingTypes_descRu.localization_value.value')"
                    v-bind:class="{'ui-no-valid': PT.validation.descRu}"
                    :error="PT.validation.descRu"
                    :errorTxt="$t(`${PT.validationTranslate.descRu}.localization_value.value`)"
                    v-model="PT.data.descRu"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100 "
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['packingTypes_descUa'])"></div>
            <DefaultInput
                    :label="$t('packingTypes_descUa.localization_value.value')"
                    v-bind:class="{'ui-no-valid': PT.validation.descUa}"
                    :error="PT.validation.descUa"
                    :errorTxt="$t(`${PT.validationTranslate.descUa}.localization_value.value`)"
                    v-model="PT.data.descUa"
            />
          </div>
        </div>

      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_cancel',
            'common_saveChange',
          ])"></div>

        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
                :value="$t('common_saveChange.localization_value.value')"
                class="buy-label__btn-i wfc"
                @click.native="change"
                v-bind:class="{'disabled-btn' : disabledBtn}"
        />

      </div>
    </template>
  </modal>
</template>

<script>
  import MainButton from "../../../UI/buttons/MainButton/MainButton";
  import Modal from "../../../commonModals/Modal";
  import DefaultInput from "../../../UI/inputs/DefaultInput/DefaultInput";
  import {PackingType} from "./models/PackingType";
  export default {
    name: "PackingTypeEditPopup",
    components: {DefaultInput, Modal, MainButton},

    props: {
      item: Object,
    },

    data() {
      return {
        PT: new PackingType(),
        disabledBtn: false,
      }
    },


    mounted() {
      this.PT.setItem(this.item)
    },

    methods: {
      change() {
        if(!this.PT.dataValidation()) return

        let data = this.PT.prepareSave()

        this.$store.dispatch('updatePackingType', {data: data, id: this.PT.getId()}).then(response => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationStatusChanged')
              this.$emit('close')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      }
    },
  }
</script>

<style scoped>

</style>
