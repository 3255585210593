<template>
  <div>
    <PackagingTypesHead
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
        @reInitTablePage="$emit('reInitTablePage')"
        @print="$emit('print')"
    />


    <PackagingTypesTable
        v-if="!_.has(getActiveTab, 'customizationType')"
        :countFilterParams="countFilterParams"
        :navTabs="navTabs"
        @showMore="$emit('showMore')"
        @resetFilter="$emit('resetFilter')"
        @reload="$emit('reload')"
        @downloadFiles="$emit('downloadFiles')"
    />

    <CustomizationOfferTable
        v-else
        :countFilterParams="countFilterParams"
        :navTabs="navTabs"
        @showMore="$emit('showMore')"
        @resetFilter="$emit('resetFilter')"
        @reload="$emit('reload')"
        @downloadFiles="$emit('downloadFiles')"
    />

  </div>
</template>

<script>


import PackagingTypesHead from "./PackagingTypesHead/PackagingTypesHead";
import PackagingTypesTable from "./PackagingTypesTable/PackagingTypesTable";
import CustomizationOfferTable
  from "@/components/modules/PackagingTypesModule/components/PackagingTypesTable/PackagingTypesTableAdmin/CustomizationOfferTable/CustomizationOfferTable";
import {CustomizationOfferMixin} from "@/mixins/customizationOfferMixins/CustomizationOfferMixin";

export default {
  name: "PackagingTypesAdmin",

  components: {
    PackagingTypesHead,
    PackagingTypesTable,
    CustomizationOfferTable,
  },

  mixins: [CustomizationOfferMixin],

  props: {
    navTabs: Object,
    countFilterParams: Number,
    filterGetParams: Object,
  },

  watch: {
    activeNav(newVal) {
      console.log(newVal);
    }
  },

  computed: {
    activeNav() {
      return this.navTabs
    }
  },

  data() {
    return {}
  },

  methods: {}
}
</script>

<style scoped>

</style>
