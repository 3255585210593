<template>
  <div class="fragment">

<!--    <SearchEngine/>-->

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate([
                  'packingTypes_pack2',
                  'packingTypes_pack3',
                  'packingTypes_pack4',
                  'packingTypes_giftPack',
                  'packingTypes_giftPapers',
                  'packingTypes_dtgPrinting',
                  'packingTypes_embroidery',
                  'packingTypes_engraving',
                  'packingTypes_leatherStamping',
                  'packingTypes_photoPrint',
                  ])"></span>
      <div class="content-tabs content-tabs--separator content-tabs--scrolling">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{
                  active: item.active,
                  'admin-edit-item': isAdmin || checkImpersonate()
               }"
               @click="$emit('changeTab', item.name)">
            <div v-if="index !== navTabs.length" class="admin-edit" @click="editPopup(item.itemType)"></div>
            <div class="content-tabs__ico">
              {{item.label}}
            </div>
          </div>
        </template>
      </div>

      <div class="scrolling-tabs" v-if="hasScrolling">
        <div class="scrolling-tabs-left hide" @click="scrollTabsLeft">❮❮</div>
        <div class="scrolling-tabs-right hide" @click="scrollTabsRight">❯❯</div>
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">

      </div>

      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['packingTypes_NewPrice'])"></div>

        <div class="mr-3"
             v-if="!_.has(getActiveTab, 'customizationType')"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_print'])"></span>
          <MainButton class="white-space-nowrap scoped-btn brown"
                      :value="$t('common_print.localization_value.value')"
                      @click.native="$emit('print')"
          >
          </MainButton>
        </div>
        <router-link
                v-if="!navTabs.gift_papers.active && !_.has(getActiveTab, 'customizationType')"
                :to="$store.getters.GET_PATHS.mainSettingsPackagingTypesCreate + (navTabs.pack5.active ? '?type=gift_pack': '')">
          <MainButton class="white-space-nowrap scoped-btn"
                      :value="$t('packingTypes_NewPrice.localization_value.value')"
                      :ico="true"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus" />
            </template>
          </MainButton>
        </router-link>
        <router-link
                v-if="navTabs.gift_papers.active"
                :to="$store.getters.GET_PATHS.mainSettingsPackagingTypesCreate + '?papers=true'">
          <MainButton class="white-space-nowrap scoped-btn wfc"
                      :value="$t('packingTypes_NewPricePapers.localization_value.value')"
                      :ico="true"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus" />
            </template>
          </MainButton>
        </router-link>
        <router-link
            v-if="_.has(getActiveTab, 'customizationType')"
                :to="$store.getters.GET_PATHS.mainSettingsCustomizationOfferCreate + '/' + getActiveTab.type">
          <MainButton class="white-space-nowrap scoped-btn wfc"
                      :value="'New ' + getActiveTab.label"
                      :ico="true"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus" />
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>

    <PackingTypeEditPopup
            v-if="isActiveEditPopup"
            :item="itemEdit"
            @close="closeEditPopup"
    />
  </div>
</template>



<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import PackingTypeEditPopup from "../../../../../../coreComponents/Popups/PackingTypeEditPopup/PackingTypeEditPopup";
  import {CustomizationOfferMixin} from "@/mixins/customizationOfferMixins/CustomizationOfferMixin";
  import {tabsScrollingMixin} from "@/mixins/globalMixins/tabsScrollingMixin";

  export default {
    name: "PackagingTypesHead",
    components: {
      PackingTypeEditPopup,
      MainButton,
      // SearchEngine,
      PlusIconSVG,
    },

    mixins: [CustomizationOfferMixin, tabsScrollingMixin],

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    computed: {
      loadedTabs() {
        return this.navTabs.pack2?.label || false
      },
    },

    watch: {
      loadedTabs() {
        this.checkHasScrolling()
      },
    },

    data(){
      return{
        isActiveEditPopup: false,
        itemEdit: {},
      }
    },

    methods: {

      editPopup(item){
        this.itemEdit = item
        this.isActiveEditPopup = true
      },

      closeEditPopup(){
        this.itemEdit = {}
        this.isActiveEditPopup = false
        this.$emit('reInitTablePage')
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";
  @import "../../../../../../../scss/colors";

  .scoped-btn{
    height: 40px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  }

  .content-tabs {
    width: 100%;
  }

  .content-tabs__item {
    position: relative;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
</style>

