<template>
  <PackagingTypesTableAdmin
          :countFilterParams="countFilterParams"
          :filterGetParams="filterGetParams"
          :navTabs="navTabs"
          @changeTab="changeTab"
          @resetFilter="resetFilter"
          @changeFilter="changeFilter"
          @showMore="showMore"
          @reload="reload"
          @reInitTablePage="initTablePage"
          @print="printBarcodes"
  />
</template>

<script>
  import PackagingTypesTableAdmin from "./PackagingTypesTableAdmin/PackagingTypesTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {CustomizationOfferMixin} from "@/mixins/customizationOfferMixins/CustomizationOfferMixin";

  export default {
    name: "PackagingTypesTable",
    components: {
      PackagingTypesTableAdmin,
    },

    mixins: [routeFilter, CustomizationOfferMixin],

    data() {
      return {
        forPage: this.$store.getters.getPackagingPriceForPage,
        page: 1,
        onePage: 1,

        filterType: 'pack2',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          pack2: {},
          pack3: {},
          pack4: {},
          pack5: {
            label: this.$t(`packingTypes_giftPack.localization_value.value`),
            name: 'pack5',
            id: 15,
            active: false
          },
          gift_papers: {
            label: this.$t(`packingTypes_giftPapers.localization_value.value`),
            name: 'gift_papers',
            id: 21,
            active: false
          },
          dtg_printing: {
            label: this.$t(`packingTypes_dtgPrinting.localization_value.value`),
            name: 'dtg_printing',
            id: 22,
            type: 'dtg_printing',
            active: false,
            customizationType: true,
          },
          embroidery: {
            label: this.$t(`packingTypes_embroidery.localization_value.value`),
            name: 'embroidery',
            id: 23,
            type: 'embroidery',
            active: false,
            customizationType: true,
          },
          engraving: {
            label: this.$t(`packingTypes_engraving.localization_value.value`),
            name: 'engraving',
            id: 24,
            type: 'engraving',
            active: false,
            customizationType: true,
          },
          leather_stamping: {
            label: this.$t(`packingTypes_leatherStamping.localization_value.value`),
            name: 'leather_stamping',
            id: 25,
            type: 'leather_stamping',
            active: false,
            customizationType: true,
          },
          photo_printing: {
            label: this.$t(`packingTypes_photoPrint.localization_value.value`),
            name: 'photo_printing',
            id: 26,
            type: 'photo_printing',
            active: false,
            customizationType: true,
          },
          photo_printing_size: {
            label: this.$t(`calculatorEngraving_productSize.localization_value.value`),
            name: 'photo_printing_size',
            id: 26,
            type: 'photo_printing_size',
            active: false,
            customizationType: true,
          },
          uv_printing: {
            label: this.$t(`packingTypes_UVPrinting.localization_value.value`),
            name: 'uv_printing',
            id: 27,
            type: 'uv_printing',
            active: false,
            customizationType: true,
          },
        },
      }
    },

    created() {

      // this.$store.dispatch('getGifPapers')

      this.initTablePage()

    },

    methods: {

      initTablePage() {
        this.$store.dispatch('getDeliveryPackingTypeGiftForAdmin')
        this.$store.dispatch('getDeliveryPackingTypeForAdmin').then(response => {
          let items = this.getRespPaginateData(response)

          items.map((item, index) => {
            this.navTabs[item.code] = {
              itemType: item,
              label: this.$t(`packingTypes_${item.code}.localization_value.value`),
              name: item.code,
              id: item.id,
              active: index === 0
            }

            if(this.$route.query.type !== undefined && item.code === this.$route.query.type){
              this.navTabs.pack2.active = false
              this.navTabs[item.code].active = true
            }
          })

          if(this.$route.query.type === 'gift_papers'){
            this.skipRadioChecked(this.navTabs, 'active')
            this.navTabs.gift_papers.active = true
          }

          if(this.$route.query.type === 'pack5'){
            this.skipRadioChecked(this.navTabs, 'active')
            this.navTabs.pack5.active = true
          }

          if(this.$route.query.type === 'dtg_printing' ||
              this.$route.query.type === 'embroidery' ||
              this.$route.query.type === 'engraving' ||
              this.$route.query.type === 'leather_stamping' ||
              this.$route.query.type === 'photo_printing'
          ){
            this.skipRadioChecked(this.navTabs, 'active')
            this.navTabs[this.$route.query.type].active = true
          }

          this.filter()
        }).catch(error => this.createErrorLog(error))
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        /**
         * If Active tab customization
         */

        if(this.getActiveTab?.customizationType){
          if (next) this.$store.commit('setNextPackagingPricePage', true)
          this.$store.dispatch('fetchCustomizationOffer', url).then(() => {
            this.$store.commit('setNextPackagingPricePage', false)
          })
          this.checkCountFilter()
          return
        }

        /**
         * If Active tab gift papers
         */
        if(this.navTabs.gift_papers.active){
          if (next) this.$store.commit('setNextPackagingPricePage', true)
          this.$store.dispatch('fetchPackagingGiftPapers', url).then(() => {
            this.$store.commit('setNextPackagingPricePage', false)
          })
          this.checkCountFilter()
          return
        }

        /**
         * If Active other tabs
         */
        if (next) this.$store.commit('setNextPackagingPricePage', true)
        this.$store.dispatch('fetchPackagingPrice', url).then(() => {
          this.$store.commit('setNextPackagingPricePage', false)
        })

        this.checkCountFilter()
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()
        if(!this.getActiveTab?.customizationType){
          myQuery.where('packing_type_id', this._.find(this.navTabs, {active: true}).id)
        }

        if(this.getActiveTab?.customizationType) {
          myQuery.where('customization_type', this._.find(this.navTabs, {active: true}).type)

          // this.generateFilterQueryParams(
          //     myQuery,
          //     {
          //       filterName: 'name',
          //       filterPrice: 'price',
          //       filterBarcode: 'barcode',
          //     },
          // )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },


      printBarcodes() {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        this.$store.dispatch('getPackagingTypeBarcode', url).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.globalDownloadBase64File('application/docx', response.data.data.fileContentBase64, 'Packing types.docx')
        }).catch(error => this.createErrorLog(error))
      },

    },

    destroyed() {
      setTimeout(() => {
        this.$store.commit('setPackagingPrice', {packagingPrice: []})
        this.$store.commit('setPackagingPriceCommonList', {packagingPriceCommonList: []})
      }, 300)
    }

  }
</script>

<style scoped>

</style>
