<template>
  <div class="custom-col custom-col--50 custom-col--md-100"
       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
    <div class="admin-edit" @click="editTranslate(['packingTypes_UserPackType'])"></div>
    <DefaultSelect
            :options="userPack"
            :value="$t('packingTypes_UserPackType.localization_value.value')"
            :otherValue="'proformData'"
            :selected="PT.data.userPackingType"
            v-bind:class="{'ui-no-valid': PT.validation.userPackingType}"
            :error="PT.validation.userPackingType"
            :errorTxt="$t(`${PT.validationTranslate.userPackingType}.localization_value.value`)"
            @change="changeUserPack"
    />
  </div>
</template>

<script>
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "PackagingTypesUserPack",
    components: {
      DefaultSelect
    },

    props: {
      PT: Object,
    },

    data() {
      return {
        userPack: [],
      }
    },

    mounted() {
      if(this.$route.query.papers){
        this.userPack = [{
          code: "gift_papers",
          currentTranslate: {name: "Gift papaers", description: "Gift papaers"},
          id: 21,
          is_active: 1,
          purpose: "gift_papers",
          translationStorage: {
            en: {name: "Gift papaers", description: "Gift papaers"},
            ru: {name: "Gift papaers", description: "Gift papaers"},
            ua: {name: "Gift papaers", description: "Gift papaers"},
          },
        }]
        this.changeUserPack(this.userPack[0])
        return
      }
      if(this.$route.query?.type === 'gift_pack') {
        this.$store.dispatch('getDeliveryPackingTypeGiftForUser').then(response => {
          this.userPack = this.getRespPaginateData(response)
          this.changeUserPack(this.userPack[0])
        }).catch(error => this.createErrorLog(error))
        return
      }
      this.$store.dispatch('getDeliveryPackingTypeForUser').then(response => {
        this.userPack = this.getRespPaginateData(response)
      })
    },

    methods: {
      changeUserPack(item) {
        this.PT.setUserPackingType(item)
      },
    }
  }
</script>

<style scoped>

</style>
