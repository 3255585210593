<template>
  <div class="fragment pt-4">
    <template v-if="$store.getters.getPackagingPrice.length > 0 && $store.getters.getPackagingPriceLoading !== true">
    <div class="custom-row">
      <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20"
           v-for="(item, index) in $store.getters.getPackagingPrice"
           :key="index">
        <div class="case-cart">
          <div class="case-cart__inner">

            <div class="case-cart__columns">
              <div class="case-cart__content-block">
                <div class="case-cart__head">
                  <div class="case-cart__label-status">
                    <div class="case-cart__label-status-i"
                         v-bind:class="{
                         active: item.enable,
                         'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()
                       }">
                      <div class="admin-edit" @click="editTranslate(['packingTypes_Active', 'packingTypes_Unactive'])"></div>
                      <template v-if="item.enable">
                        {{$t('packingTypes_Active.localization_value.value')}}
                      </template>
                      <template v-else>
                        {{$t('packingTypes_Unactive.localization_value.value')}}
                      </template>
                    </div>
                  </div>
                  <div class="case-cart__title pt-1 mt-3">
<!--                    <div class="case-cart__packaging-type-img">-->
<!--                      <img v-if="item.small_image_file" :src="'data:' + item.small_image_file.mime_type + ';base64,' + item.imageBase64" alt="ico">-->
<!--                      <img v-else src="/img/trash-group/packaging-types-img.png" alt="ico">-->
<!--                    </div>-->
                    <span class="color-black">{{item.name}}, ${{item.price}}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="case-cart__bottom">
                    <div class="case-cart__btn ml-auto"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['common_delete'])"></div>
                      <CaseCartButton
                              class="secondary-brown"
                              :value="$t('common_delete.localization_value.value')"
                              :ico="true"
                              :typeIco="'delete-brown'"
                              @click.native="removeItem(item.id, index, true)"
                      >
                      </CaseCartButton>
                    </div>
                    <div class="case-cart__btn accounts-table__case-cart-button ml-auto"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['common_edit'])"></div>
                      <router-link :to="$store.getters.GET_PATHS.mainSettingsCustomizationOfferEdit + '/' + getActiveTab.type + '/' + item.id">
                        <CaseCartButton
                                :value="$t('common_edit.localization_value.value')"
                                :ico="true"
                                :typeIco="'edit'"
                        >
                        </CaseCartButton>
                      </router-link>
                    </div>
                  </div>
          </div>
        </div>
      </div>

    </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getPackagingPriceCommonList.next_page_url !== null && $store.getters.getPackagingPrice.length > 0"
                  @click.native="$emit('showMore')"
                  v-bind:class="{'disabled-btn' : $store.getters.getNextPackagingPricePage}"
                  :count="$store.getters.getPackagingPriceCommonList.total - $store.getters.getPackagingPriceForPage < $store.getters.getPackagingPriceForPage ?
                  $store.getters.getPackagingPriceCommonList.total - $store.getters.getPackagingPriceForPage:
                  $store.getters.getPackagingPriceForPage"
          />
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getPackagingPriceLoading === false && $store.getters.getPackagingPrice.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

  </div>
</template>

<script>
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import {CustomizationOfferMixin} from "@/mixins/customizationOfferMixins/CustomizationOfferMixin";

  export default {
    name: "PackagingTypesHead",

    components: {
      NoResult,
      ShowMore,
      CaseCartButton,
    },

    mixins: [mixinDetictingMobile, CustomizationOfferMixin],

    props: {
      navTabs: Object,
      countFilterParams: Number,
    },


    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,

        isModalPackagingTypesPopup: false,
      }
    },

    methods: {
      changePackagingTypesPopup(val){
        this.isModalPackagingTypesPopup = val
      },


      closePopup(){
        // this.isModalProductsToWarehouse = false
      },

      cantDeleteProduct(){
        let text = {
          title: 'Ooops',
          txt: 'Sorry, you cannot uninstall this product',
          no: 'Ok'
        }

        let confirm = () => {
          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      removeItem(id, index){
        let text = {
          title: 'common_deleteConfirm',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {
          let deleteAction = 'deleteCustomizationOffer'

          this.$store.dispatch(deleteAction, id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let item = this.$store.getters.getPackagingPrice
              item.splice(index, 1)
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)

      },

      changeInfoPopup(val){
        this.isModalInfoPopup = val
      }

    }

  }

</script>

<style scoped lang="scss">


</style>
