import {checkValid} from "../../../../globalModels/functions/ModelsValidate";

export function PackingType() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    code: '',
    isActive: '',
    sort: '',
    nameRu: '',
    nameEn: '',
    nameUa: '',
    descRu: '',
    descEn: '',
    descUa: '',
  }


  this.validation = {
    nameRu: false,
    nameEn: false,
    nameUa: false,
    descRu: false,
    descEn: false,
    descUa: false,
  }

  this.validationTranslate = {
    nameRu: '',
    nameEn: '',
    nameUa: '',
    descRu: '',
    descEn: '',
    descUa: '',
  }

  this.validationTxt = {
    nameRu: false,
    nameEn: false,
    nameUa: false,
    descRu: false,
    descEn: false,
    descUa: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getNameRu = () => {
    return this.data.nameRu
  }
  this.getNameEn = () => {
    return this.data.nameEn
  }
  this.getNameUa = () => {
    return this.data.nameUa
  }
  this.getDescRu = () => {
    return this.data.descRu
  }
  this.getDescEn = () => {
    return this.data.descEn
  }
  this.getDescUa = () => {
    return this.data.descUa
  }
  this.getCode = () => {
    return this.data.code
  }
  this.getIsActive = () => {
    return this.data.isActive
  }
  this.getSort = () => {
    return this.data.sort
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setNameRu = (val) => {
    this.data.nameRu = val
  }
  this.setNameEn = (val) => {
    this.data.nameEn = val
  }
  this.setNameUa = (val) => {
    this.data.nameUa = val
  }
  this.setDescRu = (val) => {
    this.data.descRu = val
  }
  this.setDescEn = (val) => {
    this.data.descEn = val
  }
  this.setDescUa = (val) => {
    this.data.descUa = val
  }
  this.setCode = (val) => {
    this.data.code = val
  }
  this.setIsActive = (val) => {
    this.data.isActive = val
  }
  this.setSort = (val) => {
    this.data.sort = val
  }

}

/**
 * Global Setters
 */

PackingType.prototype.setItem = function(val) {
  this.setId(val['id'])
  this.setNameRu(val['translationStorage']['ru']['name'])
  this.setNameEn(val['translationStorage']['en']['name'])
  this.setNameUa(val['translationStorage']['ua']['name'])
  this.setDescRu(val['translationStorage']['ru']['name'])
  this.setDescEn(val['translationStorage']['en']['name'])
  this.setDescUa(val['translationStorage']['ua']['name'])
  this.setCode(val['code'])
  this.setIsActive(val['is_active'])
  this.setSort(val['sort'] ? val['sort'] : 0)
}


/**
 * Prepare Data
 */

PackingType.prototype.prepareSave = function() {

  return {
    'code': this.getCode(),
    'is_active': this.getIsActive(),
    'sort': this.getSort(),
    'translations': {
      '1': {
        'name': this.getNameEn(),
        'description': this.getDescEn(),
      },
      '2': {
        'name': this.getNameUa(),
        'description': this.getDescUa(),
      },
      '3': {
        'name': this.getNameRu(),
        'description': this.getDescRu(),
      },
    },
  }
}


/**
 * Validations
 */

PackingType.prototype.dataValidation = function() {
  let validationItems = {
    nameRu: this.getNameRu(),
    nameEn: this.getNameEn(),
    nameUa: this.getNameUa(),
    descRu: this.getDescRu(),
    descEn: this.getDescEn(),
    descUa: this.getDescUa(),
  }

  let validationOptions = {
    nameRu: {type: ['empty']},
    nameEn: {type: ['empty']},
    nameUa: {type: ['empty']},
    descRu: {type: ['empty']},
    descEn: {type: ['empty']},
    descUa: {type: ['empty']},
  }


  return this.checkValid(validationItems, validationOptions)
}
