<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
              class="clear-992"
      >
        <template slot="body">
          <PackagingTypesInfo
                  :PT="PT"
                  :type="'edit'"
          />
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'ex_backTo',
                ])"></div>
                <span class="order-create__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                          @click.native="goRouterBack()"
                  />
                </span>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['breadcrumbs_packagingTypesCreate'])"></div>
              {{$t('breadcrumbs_packagingTypesCreate.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-2"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['packingTypes_PleaseFillOut'])"></div>
              {{$t('packingTypes_PleaseFillOut.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <!--<form class="order-create__section">-->
            <!--<div class="order-create__row custom-row order-wrap">-->
              <!--<div class="custom-col custom-col&#45;&#45;66 custom-col&#45;&#45;md-100">-->

                <!--<PackagingTypesRadio-->
                        <!--v-if="!$route.query.papers"-->
                        <!--:PT="PT"-->
                <!--/>-->

                <!--<div class="section-label large"-->
                     <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                  <!--<div class="admin-edit" @click="editTranslate(['packingTypes_Name'])"></div>-->
                  <!--{{$t('packingTypes_Name.localization_value.value')}}-->
                <!--</div>-->

                <!--<div class="order-create__row custom-row">-->
                  <!--<div class="custom-col custom-col&#45;&#45;md-100"-->
                       <!--v-bind:class="{'custom-col&#45;&#45;50': !$route.query.papers}"-->
                  <!--&gt;-->
                    <!--<DefaultInput-->
                            <!--:label="$t('packingTypes_Name.localization_value.value') + ' (English)'"-->
                            <!--v-bind:class="{'ui-no-valid': PT.validation.nameEn}"-->
                            <!--:error="PT.validation.nameEn"-->
                            <!--:errorTxt="$t(`${PT.validationTranslate.nameEn}.localization_value.value`)"-->
                            <!--v-model="PT.data.nameEn"-->
                    <!--/>-->
                  <!--</div>-->
                  <!--<div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100"-->
                       <!--v-if="!$route.query.papers">-->
                    <!--<DefaultInput-->
                            <!--:label="$t('packingTypes_Name.localization_value.value') + ' (Ukrainian)'"-->
                            <!--v-bind:class="{'ui-no-valid': PT.validation.nameEn}"-->
                            <!--:error="PT.validation.nameEn"-->
                            <!--:errorTxt="$t(`${PT.validationTranslate.nameEn}.localization_value.value`)"-->
                            <!--v-model="PT.data.nameUa"-->
                    <!--/>-->
                  <!--</div>-->
                  <!--<div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100"-->
                       <!--v-if="!$route.query.papers">-->
                    <!--<DefaultInput-->
                            <!--:label="$t('packingTypes_Name.localization_value.value') + ' (Russian)'"-->
                            <!--v-bind:class="{'ui-no-valid': PT.validation.nameRu}"-->
                            <!--:error="PT.validation.nameRu"-->
                            <!--:errorTxt="$t(`${PT.validationTranslate.nameRu}.localization_value.value`)"-->
                            <!--v-model="PT.data.nameRu"-->
                    <!--/>-->
                  <!--</div>-->
                <!--</div>-->

                <!--<template v-if="!$route.query.papers">-->
                  <!--<div class="section-label large"-->
                       <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                    <!--<div class="admin-edit" @click="editTranslate(['packingTypes_Usage'])"></div>-->
                    <!--{{$t('packingTypes_Usage.localization_value.value')}}-->
                  <!--</div>-->

                  <!--<div class="order-create__row custom-row">-->

                    <!--<PackagingTypesUserPack-->
                            <!--:PT="PT"-->
                    <!--/>-->

                    <!--<div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50"-->
                         <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                      <!--<div class="admin-edit" @click="editTranslate(['packingTypes_Price'])"></div>-->
                      <!--<InputSum-->
                              <!--:label="$t('packingTypes_Price.localization_value.value')"-->
                              <!--:icoType="'dollar'"-->
                              <!--v-bind:class="{'ui-no-valid': PT.validation.price}"-->
                              <!--:error="PT.validation.price"-->
                              <!--:errorTxt="$t(`${PT.validationTranslate.price}.localization_value.value`)"-->
                              <!--v-model="PT.data.price"-->
                      <!--/>-->
                    <!--</div>-->
                  <!--</div>-->
                <!--</template>-->

                <!--<div class="order-create__row custom-row"-->
                     <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                  <!--<div class="admin-edit" @click="editTranslate(['packingTypes_Enable'])"></div>-->
                  <!--<div class="custom-col">-->
                    <!--<DefaultCheckbox-->
                            <!--:label="$t('packingTypes_Enable.localization_value.value')"-->
                            <!--:value="PT.data.status"-->
                            <!--@input="(val) => {PT.data.status = val}"-->
                    <!--/>-->
                  <!--</div>-->
                <!--</div>-->

              <!--</div>-->
              <!--&lt;!&ndash;<div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100 logo-scoped">&ndash;&gt;-->
                <!--&lt;!&ndash;<DropZone class="drop-zone-bg"&ndash;&gt;-->
                          <!--&lt;!&ndash;:parentFiles="files"&ndash;&gt;-->
                          <!--&lt;!&ndash;@changeImg="changeImg"&ndash;&gt;-->
                          <!--&lt;!&ndash;:maxSize="'5'"&ndash;&gt;-->
                <!--&lt;!&ndash;/>&ndash;&gt;-->
              <!--&lt;!&ndash;</div>&ndash;&gt;-->

              <!--<PackagingTypesFile-->
                      <!--:PT="PT"-->
              <!--/>-->
            <!--</div>-->

          <!--</form>-->

          <StepOne
                  :PT="PT"
          />


        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
              <router-link :to="$store.getters.GET_PATHS.mainSettingsPackagingTypes"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_create'])"></div>
              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_edit.localization_value.value')"
                      @click.native="$emit('save')"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  // import PackagingTypesInfo from "../../chunks/PackagingTypesInfo/PackagingTypesInfo";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  // import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  // import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import InputSum from "../../../../../UI/inputs/InputSum/InputSum";
  // import PackagingTypesRadio from "../../chunks/PackagingTypesRadio/PackagingTypesRadio";
  // import PackagingTypesUserPack from "../../chunks/PackagingTypesUserPack/PackagingTypesUserPack";
  // import PackagingTypesFile from "../../chunks/PackagingTypesFile/PackagingTypesFile";
  import StepOne from "../../components/StepOne";
  import PackagingTypesInfo from "../../chunks/PackagingTypesInfo/PackagingTypesInfo";

  export default {
    name: "PackagingTypesEditAdmin",
    components: {
      PackagingTypesInfo,
      StepOne,
      // PackagingTypesFile,
      // PackagingTypesUserPack,
      // PackagingTypesRadio,
      // DefaultInput,
      MainButton,
      CardLeftBlock,
      // PackagingTypesInfo,
      CardRightBlock,
      LinkBack,
      // DefaultCheckbox,
      // InputSum,
    },

    props: {
      PT: Object,
    },

    data() {
      return {
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, // default:

        images: 0,
      }
    },

    mounted() {

    },
  }
</script>

<style scoped lang="scss">

  @media(min-width: 992px) {

    .logo-scoped {
      padding-top: 45px;
    }

  }
</style>
