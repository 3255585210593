<template>
  <div class="user-info-admin">
    <div class="user-info-admin__inner">
      <div class="user-info-admin__caption"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['packingTypes_Created'])"></div>
        {{$t('packingTypes_Created.localization_value.value')}}
      </div>
      <div class="user-info-admin__large">
        <template v-if="type === 'create'">
          {{ new Date() | moment("DD MMM, YYYY") }}
        </template>
        <template v-else>
          {{ PT.data.date | moment("DD MMM, YYYY") }}
        </template>

      </div>

<!--      <div class="user-info-admin__history">-->
<!--        <div class="site-link text-decoration-underline btn-style brown"-->
<!--             @click="changeHistoryOfChanges(true)"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>-->
<!--          {{$t('common_CheckHistory.localization_value.value')}}-->
<!--        </div>-->
<!--      </div>-->
    </div>



  </div>
</template>

<script>


  export default {
    name: "PackagingTypesInfo",
    components: {
    },

    props: {
      PT: Object,
      type: String
    },

    data(){
      return{
        isModalHistoryOfChanges: false,
      }
    },

    methods:{
      changeHistoryOfChanges(val){
        this.isModalHistoryOfChanges= val
      },
    }
  }
</script>

<style scoped>

  @media(min-width: 680px){
    .user-info-admin__large{
      font-weight: bold;
      font-size: 40px;
      line-height: normal;
    }
  }
</style>
