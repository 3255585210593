<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
              class="clear-992"
      >
        <template slot="body">
          <PackagingTypesInfo
                  :PT="PT"
                  :type="'create'"
          />
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'ex_backTo',
                ])"></div>
                <span class="order-create__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                          @click.native="goRouterBack()"
                  />
                </span>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['breadcrumbs_packagingTypesCreate'])"></div>
              {{$t('breadcrumbs_packagingTypesCreate.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-2"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['packingTypes_PleaseFillOut'])"></div>
              {{$t('packingTypes_PleaseFillOut.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">

          <StepOne
                  :PT="PT"
          />

        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
              <router-link :to="$store.getters.GET_PATHS.mainSettingsPackagingTypes"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_create'])"></div>
              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_create.localization_value.value')"
                      @click.native="$emit('save')"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import PackagingTypesInfo from "../../chunks/PackagingTypesInfo/PackagingTypesInfo";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import StepOne from "../../components/StepOne";
  // import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  // import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import InputSum from "../../../../../UI/inputs/InputSum/InputSum";
  // import PackagingTypesRadio from "../../chunks/PackagingTypesRadio/PackagingTypesRadio";
  // import PackagingTypesUserPack from "../../chunks/PackagingTypesUserPack/PackagingTypesUserPack";
  // import PackagingTypesFile from "../../chunks/PackagingTypesFile/PackagingTypesFile";

  export default {
    name: "PackagingTypesCreateAdmin",
    components: {
      StepOne,
      // PackagingTypesFile,
      // PackagingTypesUserPack,
      // PackagingTypesRadio,
      // DefaultInput,
      MainButton,
      CardLeftBlock,
      PackagingTypesInfo,
      CardRightBlock,
      LinkBack,
      // DefaultCheckbox,
      // InputSum,
    },

    props: {
      PT: Object,
    },

    data() {
      return {
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, // default:

        images: 0,
      }
    },

    mounted() {

    },
  }
</script>

<style scoped lang="scss">

  @media(min-width: 992px) {

    .logo-scoped {
      padding-top: 45px;
    }

  }
</style>
