import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "../../../globalModels/Files";
const _ = require('lodash');


export function PackagingTypes() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    status: false,
    date: '',

    packageType: {},

    nameEn: '',
    nameUa: '',
    nameRu: '',
    nameLv: '',
    namePl: '',

    barcode: '',

    userPackingType: '',

    price: '',

    Files: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),


  }

  this.validation = {
    nameEn: false,
    nameUa: false,
    nameRu: false,
    userPackingType: false,
    price: false,
    barcode: false,
  }

  this.validationTranslate = {
    nameEn: '',
    nameUa: '',
    nameRu: '',
    userPackingType: '',
    price: '',
    barcode: '',
  }

  this.validationTxt = {
    nameEn: false,
    nameUa: false,
    nameRu: false,
    userPackingType: false,
    price: false,
    barcode: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getPackageType = () => {
    return this.data.packageType
  }
  this.getNameEn = () => {
    return this.data.nameEn
  }
  this.getNameUa = () => {
    return this.data.nameUa
  }
  this.getNameRu = () => {
    return this.data.nameRu
  }
  this.getNamePl = () => {
    return this.data.namePl
  }
  this.getNameLv = () => {
    return this.data.nameLv
  }
  this.getUserPackingType = () => {
    return this.data.userPackingType
  }
  this.getPrice = () => {
    return this.data.price
  }
  this.getBarcode = () => {
    return this.data.barcode
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setDate = (val) => {
    this.data.date = val
  }
  this.setPackageType= (val) => {
    this.data.packageType = val
  }
  this.setNameEn= (val) => {
    this.data.nameEn = val
  }
  this.setNameUa= (val) => {
    this.data.nameUa = val
  }
  this.setNameRu= (val) => {
    this.data.nameRu = val
  }
  this.setNamePl= (val) => {
    this.data.namePl = val
  }
  this.setNameLv= (val) => {
    this.data.nameLv = val
  }
  this.setUserPackingType= (val) => {
    this.data.userPackingType = val
  }
  this.setPrice= (val) => {
    this.data.price = val
  }
  this.setBarcode= (val) => {
    this.data.barcode = val
  }

}


/**
 * Global Setters
 */

PackagingTypes.prototype.setItem = function(val, ifGiftPapers) {

  if(ifGiftPapers){
    this.setId(val.id)
    this.setStatus(val['enable'])
    this.setDate(val['created_at'])
    this.setNameEn(val.translationStorage.en.title)
    this.setNameUa(val.translationStorage.ua.title)
    this.setNameRu(val.translationStorage.ru.title)
    this.setNamePl(val.translationStorage.pl.title)
    this.setNameLv(val.translationStorage.lv.title)
    this.setPrice(val.price)
    if(val.fileEntry){
      this.setPackagingTypesDownloadFiles({
        id: parseInt(val.fileEntry.id) + 1,
        mime_type: val.fileEntry.mime_type,
        imageBase64: val.imageBase64,
      })
    }
    return
  }

  this.setId(val.id)
  this.setStatus(val['is_active'] === 1)
  this.setDate(val['created_at'])
  this.setPackageType(val['packing_type'])
  this.setNameEn(val.translationStorage.en.name)
  this.setNameUa(val.translationStorage.ua.name)
  this.setNameRu(val.translationStorage.ru.name)
  this.setNamePl(val.translationStorage.pl.name)
  this.setNameLv(val.translationStorage.lv.name)
  this.setUserPackingType(val['user_packing_type'])
  this.setPrice(val.price)
  this.setBarcode(val.barcode)

  if(val.files_data){
    this.setPackagingTypesDownloadFiles({
      id: val['files']['packingPriceImage'],
      mime_type: val['files_data'][0]['mime_type'],
      imageBase64: val['files_data'][0]['fileContentBase64'],
    })
  }
}




/**
 * Functions
 */

PackagingTypes.prototype.setPackagingTypesDownloadFiles = function(val) {
  this.data.Files.pushDownloadFiles(val)
}

PackagingTypes.prototype.removePackagingTypesFile = function(id) {
  let i = -1
  _.findIndex(this.data.Files.getDownloadFiles(), function (item, index) {
    if (item[id] !== undefined) i = index
  });

  this.data.Files.removeDownloadFile(i)
}



/**
 * Validations
 */

PackagingTypes.prototype.firstValidation = function (giftPapers) {

  let data = this.data

  let validationItems = {
    nameEn: data.nameEn,
    nameUa: data.nameUa,
    nameRu: data.nameRu,
    namePl: data.namePl,
    nameLv: data.nameLv,
    userPackingType: data.userPackingType,
    price: data.price,
  }

  let validationOptions = {}

  // if(giftPapers){
  //   validationOptions = {
  //     nameEn: {type: ['empty']},
  //   }
  // } else {
    validationOptions = {
      nameEn: {type: ['empty']},
      nameUa: {type: ['empty']},
      nameRu: {type: ['empty']},
      namePl: {type: ['empty']},
      nameLv: {type: ['empty']},
      price: {type: giftPapers ? [] : ['numeric', 'not-zero', 'empty']},
    // }
  }

  return (this.checkValid(validationItems, validationOptions) && !data.Files.getFilesMaxSizeError())
}



/**
 * Prepare Data
 */

PackagingTypes.prototype.prepareSave = function(lang, ifGiftPapers) {



  let request = {
    "packing_type_id": this.getPackageType().id,
    "is_active": this.getStatus() ? 1 : 0,
    "sort": 100,
    "price": this.getPrice(),
    "translations":{},
    "file_base64": null,
    "barcode": this.getBarcode()
  }

  if(this.getUserPackingType()?.id){
    request["user_packing_type_id"] = this.getUserPackingType()?.id
  }

  lang.map(item => {
    switch (item.locale) {
      case 'en':
        request["translations"][item.id] = {
          name: this.getNameEn(),
          description: ''
        }
        if(ifGiftPapers) {
          request["translations"][item.id] = {
            title: this.getNameEn(),
          }
        }
        break
      case 'ua':
        request["translations"][item.id] = {
          name: this.getNameUa(),
          description: ''
        }
        if(ifGiftPapers) {
          request["translations"][item.id] = {
            title: this.getNameUa(),
          }
        }
        break
      case 'ru':
        request["translations"][item.id] = {
          name: this.getNameRu(),
          description: ''
        }
        if(ifGiftPapers) {
          request["translations"][item.id] = {
            title: this.getNameRu(),
          }
        }
        break
      case 'pl':
        request["translations"][item.id] = {
          name: this.getNamePl(),
          description: ''
        }
        if(ifGiftPapers) {
          request["translations"][item.id] = {
            title: this.getNamePl(),
          }
        }
        break
      case 'lv':
        request["translations"][item.id] = {
          name: this.getNameLv(),
          description: ''
        }
        if(ifGiftPapers) {
          request["translations"][item.id] = {
            title: this.getNameLv(),
          }
        }
        break
    }
  })

  if(this.data.Files.getFiles().length > 0 ) {
    request['file_base64'] = this.data.Files.getFiles()[0].base64String
  }


  if(ifGiftPapers){
    return {
      "enable": this.getStatus(),
      "name": this.getNameEn(),
      "price": this.getPrice(),
      "translations": request["translations"],
    }
  }

  return request
}
