<template>
  <PackagingTypesEditAdmin
          v-if="isAdmin"
          :PT="PT"
          @save="save({edit: true, id: $route.params.id})"
  />
</template>

<script>
  import PackagingTypesEditAdmin from "./PackagingTypesEditAdmin/PackagingTypesEditAdmin";
  import {PackagingTypes} from "../../models/PackagingTypes";
  import {packagingTypesMixin} from "../../../../../mixins/packagingTypesMixins/packagingTypesMixin";

  export default {
    name: "PackagingTypesEdit",
    components: {
      PackagingTypesEditAdmin,
    },

    mixins: [packagingTypesMixin],

    data() {
      return {
        PT: new PackagingTypes(),
      }
    },


    mounted() {
      let ifGiftPapers = this.$route.query.papers
      if(ifGiftPapers){

        this.$store.dispatch('getGiftPapers', this.$route.params.id).then(() => {
          this.PT.setItem(this.$store.getters.getPackagingPriceItem, ifGiftPapers)

          // if(this._.has(this.$store.getters.getPackagingPriceItem, 'files')){
          //   this.$store.dispatch('getFileFromServer', this.$store.getters.getPackagingPriceItem.files.packingPriceImage)
          // }
        }).catch(error => this.createErrorLog(error))
        return
      }

      this.$store.dispatch('getPackagingPrice', this.$route.params.id).then(() => {
        this.PT.setItem(this.$store.getters.getPackagingPriceItem)

        // if(this._.has(this.$store.getters.getPackagingPriceItem, 'files')){
        //   this.$store.dispatch('getFileFromServer', this.$store.getters.getPackagingPriceItem.files.packingPriceImage)
        // }
      }).catch(error => this.createErrorLog(error))
    },

    methods: {


    }

  }
</script>

<style scoped>

</style>
